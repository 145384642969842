import { forwardRef, type SVGProps } from "react";

import { cn } from "@intergamma/common/cn";

export interface PlusIconProps extends SVGProps<SVGSVGElement> {}

export const Plus = forwardRef<SVGSVGElement, PlusIconProps>(
  ({ className, ...props }, ref) => (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        className={cn("karwei:hidden", className)}
        {...props}
      >
        <path
          d="M11 19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H13V5C13 4.44771 12.5523 4 12 4C11.4477 4 11 4.44771 11 5V11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H11V19Z"
          fill="currentColor"
        />
      </svg>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        className={cn("gamma:hidden", className)}
        {...props}
      >
        <path
          d="M11.5 12.5V20H12.5V12.5H20V11.5H12.5V4H11.5V11.5H4V12.5H11.5Z"
          fill="currentColor"
        />
      </svg>
    </>
  ),
);

(Plus as React.FC).displayName = "Plus";
