import "@adyen/adyen-web/styles/adyen.css"
import { IntergammaToastContainer } from "@intergamma/global-styling"
import { useCheckoutPageQuery } from "api/checkout-page"
import { useCurrentCustomerQuery } from "api/current-customer"
import { DatadogContext } from "components/DatadogContext"
import { Footer } from "components/Footer"
import { Header } from "components/Header"
import { Main } from "components/Main"
import { Page } from "components/Page"
import { useDispatchTrackCheckoutEmptyCart } from "features/checkout/hooks/tracking"
import { usePagesConfig } from "hooks/usePagesConfig"
import { log } from "lib/datadog-logging"
import { lazy, useEffect } from "react"
import { useNavigate } from "react-router"
import "./adyen.css"

const Checkout = lazy(() =>
  import(/* webpackChunkName: "checkout" */ "features/checkout/components/Checkout").then((module) => ({
    default: module.Checkout,
  }))
)

export function CheckoutPage() {
  const navigate = useNavigate()
  const { cart } = usePagesConfig()
  const dispatchTrackCheckoutEmptyCart = useDispatchTrackCheckoutEmptyCart()
  const checkoutPageQuery = useCheckoutPageQuery()
  const currentCustomerQuery = useCurrentCustomerQuery()

  useEffect(() => {
    if (!checkoutPageQuery.isFetching && checkoutPageQuery.isError) {
      navigate(cart.relativeUrl, { replace: true })
      log.warn(
        "[checkout-form] Redirected the user to the cart page. User visited the checkout form page with an empty cart."
      )
      dispatchTrackCheckoutEmptyCart()
    }
  }, [cart.relativeUrl, checkoutPageQuery, dispatchTrackCheckoutEmptyCart, navigate])

  if (!checkoutPageQuery.isSuccess || !currentCustomerQuery.isSuccess) {
    return null
  }

  return (
    <Page moduleName="checkout">
      <Header variant="simplified" />
      <IntergammaToastContainer />
      <Main>
        <DatadogContext context={{ cartUid: checkoutPageQuery.data?.cartSummary.cartUid }}>
          <Checkout />
        </DatadogContext>
      </Main>
      <Footer />
    </Page>
  )
}
