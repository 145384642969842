import { forwardRef, type SVGProps } from "react";

import { cn } from "@intergamma/common/cn";

export interface MinusIconProps extends SVGProps<SVGSVGElement> {}

export const Minus = forwardRef<SVGSVGElement, MinusIconProps>(
  ({ className, ...props }, ref) => (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        className={cn("karwei:hidden", className)}
        {...props}
      >
        <path
          d="M4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12Z"
          fill="currentColor"
        />
      </svg>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        className={cn("gamma:hidden", className)}
        {...props}
      >
        <path d="M4 11.5H20V12.5H4V11.5Z" fill="currentColor" />
      </svg>
    </>
  ),
);

(Minus as React.FC).displayName = "Minus";
