var u = Object.defineProperty;
var l = (e, t, r) => t in e ? u(e, t, { enumerable: !0, configurable: !0, writable: !0, value: r }) : e[t] = r;
var s = (e, t, r) => l(e, typeof t != "symbol" ? t + "" : t, r);
import { i, s as n, g as a } from "./isKiosk-tk3GxS9e.js";
import { a as I } from "./isKiosk-tk3GxS9e.js";
const d = {
  isKiosk: !0,
  id: "Random ID",
  storeUID: "",
  startUrl: "/assortiment/k/behang",
  hasPinTerminal: !0,
  pinTerminalId: "P400Plus-275103114",
  store: "EERKU",
  version: "",
  storeDepartment: "Behang",
  screenSaverIdleTime: 1e3 * 60 * 5,
  // 5 minutes
  screenSaverVideoUrl: "https://videos.ctfassets.net/7k5qhlbm8iou/3tzHccUyiZocyPvBtsNoRb/7c8e35265b08dcbd9fd708b881e4a3cb/screensaver.mp4",
  // Video of gamma
  debugEnabledTime: null
};
function o(e) {
  return {
    ...d,
    ...e
  };
}
async function m() {
  if (i())
    return o();
  try {
    return await n(
      a(),
      "GET_KIOSK_DATA"
    );
  } catch {
    return {
      isKiosk: !1
    };
  }
}
async function E() {
  if (process.env.NODE_ENV === "development")
    return console.log(
      "Cache cleared in development mode. Faking clear cache function"
    ), !0;
  try {
    return await n(
      a(),
      "CLEAR_ALL_CACHE"
    ), !0;
  } catch {
    return !1;
  }
}
async function p() {
  const e = i() ? o() : await m();
  if (!(!e.isKiosk || !e.hasPinTerminal))
    return {
      terminalId: e.pinTerminalId,
      kioskId: `${e.store}-${e.storeDepartment}`
    };
}
class f extends Error {
  constructor(r, c) {
    super(r);
    s(this, "fieldName");
    this.fieldName = c;
  }
}
async function D(e) {
  try {
    return await n(
      a(),
      "SET_KIOSK_DATA",
      e
    );
  } catch (t) {
    throw "name" in t && "message" in t ? new f(t.message, t.name) : new Error("Something went wrong");
  }
}
async function K(e) {
  try {
    return (await n(
      a(),
      "VALIDATE_CREDENTIALS",
      e
    )).role;
  } catch {
    return null;
  }
}
export {
  f as SetKioskDataError,
  E as clearCache,
  a as getExtensionId,
  m as getKioskData,
  p as getKioskPaymentData,
  I as isKiosk,
  D as setKioskData,
  K as validateCredentials
};
