import { dateRegex as h, beMunicipalityRegex as m, bePostalCodeRegex as b, nlPostalCodeRegex as N, emailRegex as R, forbiddenCharactersRegex as y, giftcardNumberRegex as I, naturalNumberRegex as S, notBlankRegex as D, phoneNumberRegex as E, receiptNumberInputRegex as F, unsignedIntegerRegex as C, streetNameRegex as L, vatNumberInputRegex as P } from "@intergamma/regex";
import { trim as l } from "@intergamma/formatters";
function B(e, t) {
  return typeof e == "function" ? e(t) : e;
}
function c(e) {
  return (t, r) => (n) => e(n, r) === !0 ? null : B(t, r);
}
const G = c((e) => {
  if (!e)
    return !0;
  const t = g(e);
  if (!t)
    return !1;
  const { d: r, m: n, y: s, date: i } = t;
  return !Number.isNaN(i) && r === i.getDate() && n === i.getMonth() + 1 && s === i.getFullYear();
}), M = c((e, { min: t }) => {
  if (!e)
    return !0;
  const r = g(e);
  if (!r)
    return !0;
  const n = g(t);
  return n ? r.date >= n.date : !0;
}), V = c((e, { max: t }) => {
  if (!e)
    return !0;
  const r = g(e);
  if (!r)
    return !0;
  const n = g(t);
  return n ? r.date <= n.date : !0;
});
function g(e = "") {
  if (e instanceof Date)
    return {
      d: e.getDate(),
      m: e.getMonth() + 1,
      y: e.getFullYear(),
      date: e
    };
  const t = e.match(h);
  if (!t)
    return null;
  const r = parseInt(t[1], 10), n = parseInt(t[2], 10), s = parseInt(t[3], 10);
  return { d: r, m: n, y: s, date: new Date(s, n - 1, r) };
}
const k = c(
  (e) => !e || m.test(e)
), $ = c(
  (e) => !e || b.test(e)
), H = c(
  (e) => !e || N.test(e)
);
function U(...e) {
  return (t) => e.filter(Boolean).reduce(
    (r, n) => r || n(t),
    null
  );
}
const Y = (e) => (t) => {
  const r = t.split("").reduce((n, s) => (s.match(/[a-zA-Z0-9.@!#$%&'*+\-/=?^_`{|}~]/g) || n.add(s), n), /* @__PURE__ */ new Set());
  return r.size === 0 ? null : e(r);
}, w = c(
  (e) => !e || R.test(e)
), A = c(
  (e, { array: t }) => !t.includes(e)
), Z = c(
  (e) => !y.test(e)
), j = c(
  (e) => !e || I.test(e)
), z = c(
  (e, { min: t }) => !e || parseFloat(String(e)) > t
), q = c(
  (e, { max: t }) => !e || parseFloat(String(e)) < t
), W = c(
  (e, { max: t }) => !e || parseFloat(String(e)) <= t
), _ = c(
  (e, { min: t }) => !e || parseFloat(String(e)) >= t
), J = c(
  (e, { array: t }) => t.includes(e)
), Q = c((e, { prefixes: t }) => e.length !== 13 || !t.find((n) => e.indexOf(n) === 0) ? !1 : e.split("").reduce((n, s, i) => i % 2 === 0 ? n + Number(s) : n + 3 * Number(s), 0) % 10 === 0), X = c(
  (e, { max: t }) => !e || e.length <= t
), v = c(
  (e, { min: t }) => !e || e.length >= t
), ee = c(
  (e) => !e || S.test(String(e))
), te = c(
  (e) => !D.test(e)
), ne = c(
  // eslint-disable-next-line no-prototype-builtins
  (e, { key: t }) => !e || typeof e == "object" && e.hasOwnProperty(t)
);
function a(e, t) {
  const r = [];
  for (let n = e; n <= t; n += 1)
    r.push(n);
  return r;
}
const p = {
  AT: { cc: "AT", prefix: "43", lengths: a(4, 13) },
  // Austria
  BE: { cc: "BE", prefix: "32", lengths: [8, 9] },
  // Belgium
  BG: { cc: "BG", prefix: "359", lengths: [7, 8, 9] },
  // Bulgaria
  CY: { cc: "CY", prefix: "357", lengths: [8] },
  // Cyprus
  CZ: { cc: "CZ", prefix: "420", lengths: [9] },
  // Czech Republic
  DE: { cc: "DE", prefix: "49", lengths: a(3, 12) },
  // Germany
  DK: { cc: "DK", prefix: "45", lengths: [8] },
  // Denmark
  EE: { cc: "EE", prefix: "372", lengths: [7, 8] },
  // Estonia
  ES: { cc: "ES", prefix: "34", lengths: [9] },
  // Spain
  FI: { cc: "FI", prefix: "358", lengths: a(5, 12) },
  // Finland
  FR: { cc: "FR", prefix: "33", lengths: [9] },
  // France
  GB: { cc: "GB", prefix: "44", lengths: [9, 10] },
  // United Kingdom
  GR: { cc: "GR", prefix: "30", lengths: [10] },
  // Greece
  HR: { cc: "HR", prefix: "385", lengths: [8, 9] },
  // Croatia
  HU: { cc: "HU", prefix: "36", lengths: [8, 9] },
  // Hungary
  IE: { cc: "IE", prefix: "353", lengths: a(7, 10) },
  // Ireland
  IT: { cc: "IT", prefix: "39", lengths: a(8, 12) },
  // Italy
  LV: { cc: "LV", prefix: "371", lengths: [8] },
  // Latvia
  LT: { cc: "LT", prefix: "370", lengths: [8] },
  // Lithuania
  LU: { cc: "LU", prefix: "352", lengths: [8, 9, 12] },
  // Luxembourg
  MT: { cc: "MT", prefix: "356", lengths: [8] },
  // Malta
  NL: { cc: "NL", prefix: "31", lengths: [9] },
  // The Netherlands
  PL: { cc: "PL", prefix: "48", lengths: [9] },
  // Poland
  PT: { cc: "PT", prefix: "351", lengths: [9] },
  // Portugal
  RO: { cc: "RO", prefix: "40", lengths: [9] },
  // Romania
  SE: { cc: "SE", prefix: "46", lengths: a(6, 9) },
  // Sweden
  SI: { cc: "SI", prefix: "386", lengths: [8] },
  // Slovenia
  SK: { cc: "SK", prefix: "421", lengths: [9] }
  // Slovakia
};
function T(e, t, r = !1, n = !1) {
  if (!E.test(e))
    return !1;
  const s = r ? [p[t]].filter(Boolean) : Object.keys(p).map((u) => p[u]), i = (u, o, f) => {
    if (o.indexOf(`+${f}`) === 0) return `+${f}`;
    if (o.indexOf(`00${f}`) === 0) return `00${f}`;
    if (u === t && o[0] === "0") return "0";
    if (n) return "";
  };
  return !!s.find(({ cc: u, prefix: o, lengths: f }) => {
    const d = i(u, e, o);
    if (d === void 0) return !1;
    const x = e.slice(d.length).replace(/[^0-9]*/g, "");
    return f.indexOf(x.length) !== -1;
  });
}
const re = c(
  (e, { country: t, local: r }) => !e || T(e, t, r)
), ce = c(
  (e) => F.test(e)
), se = c((e) => !!e || e === 0), ie = c(
  (e) => !e || C.test(String(e))
), oe = (e) => (t) => {
  const [, r, n, s] = L.exec(t) || [];
  if (!n)
    return !0;
  const i = {
    street: l(r),
    streetNumber: l(n),
    apartment: l(s)
  };
  return typeof e == "function" ? e(i) : e;
}, fe = c(
  (e) => P.test(e)
);
export {
  k as beMunicipality,
  $ as bePostalCode,
  U as compose,
  G as date,
  w as email,
  A as excludes,
  Z as forbiddenCharacters,
  j as giftcardNumber,
  z as gt,
  J as includes,
  T as isValidPhoneNumber,
  Q as loyaltyCardNumber,
  q as lt,
  W as max,
  V as maxDate,
  X as maxLength,
  _ as min,
  M as minDate,
  v as minLength,
  ee as naturalNumber,
  H as nlPostalCode,
  te as notBlank,
  ne as objectWithKey,
  p as phoneFormats,
  re as phoneNumber,
  ce as receiptNumber,
  se as required,
  oe as streetName,
  ie as unsignedInteger,
  Y as validCharacters,
  fe as vatNumber
};
