import { ComponentPropsWithRef, forwardRef } from "react";

import { useTranslation } from "react-i18next";
import type { PopoverContentProps } from "@radix-ui/react-popover";

import { Tooltip } from "../tooltip/Tooltip";
import { Info } from "../icons";

type LabelProps = ComponentPropsWithRef<"label"> & {
  info?: {
    title: string;
    content: string;
    onChange?: (open: boolean) => void;
    side?: PopoverContentProps["side"];
  };
  optional?: boolean;
};

export const Label = forwardRef<HTMLLabelElement, LabelProps>(
  // eslint-disable-next-line prefer-arrow-callback
  function Label({ htmlFor, info, optional, ...props }, ref) {
    const { t } = useTranslation(["ig-form"]);

    return (
      <div className="flex items-center gap-1 text-75/6 gamma:text-neutral-700 karwei:text-brand-primary">
        <label className="font-bold" htmlFor={htmlFor} ref={ref} {...props} />
        {info && (
          <Tooltip {...info}>
            <span className="flex size-6 items-center text-brand-primary hover:opacity-80">
              <Info />
            </span>
          </Tooltip>
        )}
        {optional && <span>{t("ig-form:optional")}</span>}
      </div>
    );
  },
);
