import { getDate, getMonth, getYear, parseISO } from "date-fns"
import type { TFunction } from "i18next"

const offsetDays = ["Vandaag", "Morgen", "Overmorgen"]

export const zeroPad = (num: number) => String(num).padStart(2, "0")

export const getDay = (date: Date, t: TFunction, allowOffset = true) => {
  const dayOffset = Math.ceil((date.getTime() - new Date().getTime()) / (1000 * 3600 * 24))
  if (allowOffset && offsetDays[dayOffset]) {
    return `${t(`checkout-common:OffsetDays.${dayOffset}`)} (${t(`checkout-common:Days.${date.getDay()}`).toLowerCase()})`
  }
  return `${t(`checkout-common:Days.${date.getDay()}`)} ${date.getDate()} ${t(`checkout-common:Months.${date.getMonth()}`).toLowerCase()}`
}

export function getDayName(date: Date, { t }: { t: TFunction }) {
  const dayOffset = Math.ceil((date.getTime() - new Date().getTime()) / (1000 * 3600 * 24))

  if (offsetDays[dayOffset]) {
    return `${t(`checkout-common:OffsetDays.${dayOffset}`)} (${t(`checkout-common:Days.${date.getDay()}`).toLowerCase()})`
  }

  return `${t(`checkout-common:Days.${date.getDay()}`)}`
}

export function getDateTime(date: Date, { t }: { t: TFunction }) {
  return `${date.getDate()} ${t(`checkout-common:Months.${date.getMonth()}`).toLowerCase()}`
}

export function getDateString(date: Date | undefined) {
  if (!date) return undefined

  return `${date.getFullYear()}-${zeroPad(date.getMonth() + 1)}-${zeroPad(date.getDate())}`
}

export function getDateStringFull(date: Date | undefined, t: TFunction) {
  if (!date) return undefined

  return `${getDay(date, t, false)} ${date.getFullYear()}`
}

// array to  Date object: ea [2020, 12, 4] to a JS date
export function arrayToDate(datePartsArray: number[]) {
  return new Date(datePartsArray[0], datePartsArray[1] - 1, datePartsArray[2])
}

/**
 * @example
 * dateWithHoursToArray(new Date("2020-12-1"), "16") returns [2020, 12, 1, 16, 0, 0]
 */
export function dateWithHoursToArray(date: Date, hours: number | string): number[] {
  return [getYear(date), getMonth(date) + 1, getDate(date), Number(hours), 0, 0]
}

export function formatDeliveryPromiseDate(locale: string, date?: string) {
  if (!date) return undefined

  try {
    const parsedDate = parseISO(date)
    return new Intl.DateTimeFormat(locale, { day: "numeric", month: "long", year: "numeric" }).format(parsedDate)
  } catch {
    return undefined
  }
}
